import React from 'react'
import "./Statutory.css"
import  epf1 from "../../asset/services/statutory/-epf.png"
import  epf2 from "../../asset/services/statutory/ESIC-Hospitals.png"
import  epf3 from "../../asset/services/statutory/epf-2"
import  epf4 from "../../asset/lwf.jpg"
function Statutory() {
  return (
    <div className="container-fluid">
        <div className="row  pt-5">
        <div className="container-fluid statutory-1 p-0"    >
            <div className="statutory-11 pt-4 ">
                <div className="col-md-8 mt-5 pt-5 ps-5 statutoryconhead-1 ">
                    <h1 className='text-uppercase'>Statutory Compliance</h1>
                    <h5>Adherence to the statutory benefits creates an inclusive works environment that meets the needs of all employees.</h5>
                </div>
            </div>
        </div>
        <div className="col-md-12 mt-4">
            <div className="row">
                <div className="col-md-12 text-center">
                    <h1 className='statutory-cont-data-head text-uppercase'>Employee Provident Fund Organization</h1>
                </div>
                <div className="col-md-11 statutoryconhead-3">
                    <div className="row ">
                            <div className="col-md-6 d-flex justify-content-center">
                                <img src={epf1} alt="" />
                            </div>
                            <div className="col-md-6">
                            <p className='pt-3 mt-5'>
                            Every employer who has 20 or more employees (including third party contractor) should register themselves under EPFO.  Employee has to contribute 12% of his/her Basic+DA towards Provident Fund (PF) and the same amount of share has to be made by employer. Apart from this, the employer has to pay 1 % of administration charges to the RPFO.
                            </p>
                            </div>
                    </div>
                </div>

            </div>
        </div>
        <div className="col-md-12 mt-4">
            <div className="row statutoryconhead-3">
                <div className="col-md-12 text-center">
                    <h1 className='statutory-cont-data-head text-uppercase'>Significance impact of noncontributing the PF</h1>
                </div>
                <div className="row  justify-content-center">
                    <div className="col-md-11 d-flex justify-content-center my-4">
                        <img src={epf2} alt="" />
                    </div>
                    <div className="col-md-11 ">
                        <p className=''>
                            As per section (1A) of EPF & MP Act, 1952, an employer who contravenes, or makes default in complying with, the provisions of section 6 or clause a of sub-section 3 of section 17 in so far as it relates to the payment of inspection charges, or paragraph 38 of the Scheme in so far as it relates to the payment of administrative charges, shall be punishable with imprisonment for a term which may extend to three years but – <br />

                            (a) which shall not be less than one year and a fine of ten thousand rupees in case of default in payment of the employees’ contribution which has been deducted by the employer from the employees’ wages; <br />

                            (b) which shall not be less than six months and a fine of five thousand rupees, in any other case: Provided that the Court may, for any adequate and special reasons to be recorded in the judgment, impose a sentence of imprisonment for a lesser term. <br />

                            Apart from that, interest at the rate 12% (Rule 7Q) per annum and damages at the rate 17% (Rule 14B) per annum till September 2008 and 5% (Rule 14B) per annum after October 2008. <br />

                            <span>Accunity HR</span> helps you to adhere to all statutory requirements under EPFMP act. Also, we will handle any inspections, notices or cases that may ascend. <br />

                            Handling employee query is the most critical and difficult task. Accunity HR has trained and highly skilled workforce who handle your employee queries and help them to resolve issues with respect to provident fund. <br />


                        </p>
                    </div>

                </div>
            </div>
        </div>

        <div className="col-md-12 mt-4">
            <div className="row statutoryconhead-3">
                <div className="col-md-12 text-center">
                    <h1 className='statutory-cont-data-head text-uppercase'>Significance impact of non-compliance under ESIC Act </h1>
                </div>
                <div className="row justify-content-center">

                    <div className="col-md-11 ">
                        <p>
                        As per section 85, 85(a) to 86(g) 85A and 85B, a non complied employer to be punishable with imprisonment for a term which may extend to 24 months [two years] and with fine of five thousand rupees: <br />
                        Along with penalties and damages. <br />
                        Penalties as under:
                        <div className="col-md-12 d-flex justify-content-center">
                            <div className="col-md-6 d-flex justify-content-center">
                            <table class="table table-bordered">
                                <tbody>
                                    <tr>
                                    <th scope="row">i)</th>
                                    <td>If delay is less than 2 months </td>
                                    <td>  If delay is less than 2 months 	@ 5% per annum</td>
                                    </tr>
                                    <tr>
                                    <th scope="row">ii)   </th>
                                    <td>If delay is for 2 months & above
                                        but less than 4 months
                                    </td>
                                    <td>@ 10% per annum</td>
                                    </tr>
                                    <tr>
                                    <th scope="row">iii)   </th>
                                    <td>If delay is for  4 months & above
                                        but less than 6 months
                                    </td>
                                    <td>@ 15% per annum     </td>
                                    </tr>
                                    <tr>
                                    <th scope="row">iv)   </th>
                                    <td>If delay is more than 6 months  </td>
                                    <td>@ 25% per annum</td>
                                    </tr>
                                </tbody>
                                </table>
                            </div>
                        </div>
                        Accunity HR provides you end to end service in maintaining ESIC compliance.  Starting from Obtaining ESI registration, adding new joiners & generating IPs, exiting non eligible & separated employees, generating monthly contribution challan, monthly returns, half yearly returns, handling inspections and notices, etc.

                        </p>

                    </div>

                </div>
            </div>
        </div>
        <div className="col-md-12 mt-4">
            <div className="row statutoryconhead-3">
                <div className="col-md-12 text-center">
                    <h1 className='statutory-cont-data-head text-uppercase'>Professional tax</h1>
                </div>
                <div className="row justify-content-center">
                    <div className="col-md-12 text-center my-4">
                        <img src={epf3} alt="" />
                    </div>
                    <div className="col-md-11 ">
                        <h5>Significance impact of not remitting the PT to government: </h5>
                        <p>If an enrolled person or a registered employer fails, without reasonable cause, to make payment of any amount of tax within the required time or date as specified in the notice of demand the assessing authority may, after giving him a reasonable opportunity of making representation, impose upon him a penalty not exceeding fifty per cent of the amount of tax due. This penalty shall be in addition to the interest payable under sub-section (2) or (3) of section 11. <br />

                        Apart from that, the interest at the rate of 12% per annum <br />
                        Damages at the rate of 5% per annum for less than 2 months <br />
                        10% for 2 to 4 months <br />
                        15% for 4 to 6 months <br />
                        25% for 6 months and above. <br />

                        Accunity HR helps you in complaining with professional tax requirements including registration, enrollment, remittance returns, notices etc.
                        </p>
                        {/* <h5>Liaison with government department</h5>
                        <p>Liaison with government department to get registrations, submission of returns, if any notice received, to close notices, will also be done by Accunity HR</p> */}

                    </div>

                </div>
            </div>
        </div>
        <div className="col-md-12 mt-4">
            <div className="row statutoryconhead-3">
                <div className="col-md-12 text-center">
                    <h1 className='statutory-cont-data-head text-uppercase'>labour welfare fund contribution (LWF)</h1>
                </div>
                <div className="row  justify-content-center">
                    <div className="col-md-11 d-flex justify-content-center my-4">
                        <img src={epf4} alt="" />
                    </div>
                    <div className="col-md-11 ">
                    <h5>labour welfare fund contribution (LWF):</h5>
                        <p>
                        Every state has been defined labour welfare fund, it is varied from state to state. For example, <br />
                        &gt;&gt; Haryana state monthly deduction is applicable, employee contribution Rs.10 and employer contribution Rs.20 per employee. <br />
                        &gt;&gt; Maharashtra state half yearly deduction is applicable, employee contribution Rs.12 and employer contribution Rs.36. June and December month. <br />
                        &gt;&gt; Karnataka state yearly deduction is applicable, employee contribution Rs.20 and employer contribution Rs.40

                        </p>
                        <h5>Significance impact of non-deduction of LWF  </h5>
                        <p>as per section 17B of The Karnataka Labour Welfare Fund Act, Any person who willfully contravenes any provision of the Act shall on conviction be punished for the first offence with fine which may extend to five hundred rupees or with imprisonment for a term which may extend to three months and for the second and subsequent offences, with fine which may extend to one thousand rupees or with imprisonment for a term which may extend to one year or with both <br /> <br /> Accunity HR manages registration and contribution of LWF as required by each state. </p>
                    </div>

                </div>
            </div>
        </div>
</div>
    </div>
  )
}
export default Statutory
