import React ,{useState,useEffect} from 'react';
import "./MinwageSingledata.css"
import axios from 'axios'
import { useParams } from 'react-router-dom';
import {IoMdArrowRoundBack} from 'react-icons/io';
import nodatafound from "../asset/no-data-found.gif"



function MinwageSingledata() {
  const { minState,minact } = useParams();
  const [minwagesing,setminwagesing]=useState();
  const [minwagehead,setminwagehead]=useState();
  const [selectedValue, setSelectedValue] = useState();
  const [noValue, setnoValue] = useState();

    const options = [
        { value: 'Shops & Commercial Establishments', label: 'select act' },
        { value: 'Shops & Commercial Establishments', label: 'Shops & Commercial Establishments' },
        { value: 'Chemist and Druggist Shops', label: 'Chemist and Druggist Shops' },
        { value: 'Hair Dressing Saloon', label: 'Hair Dressing Saloon/Beauty Parlour' },
        { value: 'Photo Studios', label: 'Photo Studios' },
        { value: 'Service shops ', label: 'Service shops (Watch/Radio/Bicycle)' },
        { value: 'Optical shops', label: 'Optical shops' },
        { value: 'Block Making and Rubber Stamp making', label: 'Block Making and Rubber Stamp making' },
        { value: 'Electrical Light and Sound Service shops', label: 'Electrical Light and Sound Service shops' },
        { value: 'Diagnostic Laboratory', label: 'Diagnostic Laboratory' },
        { value: 'Chit Funds, Co-operative', label: 'Chit Funds, Co-operative' },
        { value: 'Boarding and Lodges', label: 'Boarding and Lodges' },
        { value: 'Furniture', label: 'Furniture' },
        { value: 'Miscellaneous Workers', label: 'Miscellaneous Workers' },

    ];

    const handleChange = (event) => {
        setSelectedValue(event.target.value);
        const stateddata=event.target.value
        window.location.href=(stateddata);
    };

   useEffect(() => {
    const fetchingdata = async () => {
      try {
        const response = await axios.get(`https://websitenode.accunityhr.com/minimumWagesWeb/${minState}/${minact}`);
        console.log('Data fetched successfully:', response.data);
        setminwagesing(response.data.statedata);
        setminwagehead(response.data.stateheader);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchingdata();
  }, [minState, minact]);


  if(!minwagesing) return null;



  return(
    <div className='container-fluid MinwageSingledatamain'>
        <div className="row">
            <div className="col-md-12 mt-5">
                <div className="row justify-content-center">
                    <div className="col-md-10 mt-5">
                        {/* User: {minState} */}
                        <div className="row">
                            <div className="col-md-12">
                            <div className="row justify-content-between">
                            {/* <button type="button" className="btn btn-success col-md-1"><a href="/library/minimum-wages" className='text-light minimum-wagesbklik'><IoMdArrowRoundBack/>back</a></button> */}
                            <div className="col-md-2">
                            <button type="button" className="btn btn-success btn-sm"><a href="/library/minimum-wages" className='text-light minimum-wagesbklik'><IoMdArrowRoundBack/>back</a></button>
                            </div>
                                <div className="col-md-4 mt-3 m-md-0">
                                <select className=" form-select col-md-3" value={selectedValue} onChange={handleChange}>
                                    {options.map(option => (
                                        <option className='col-md-6 sm-4' key={option.value} value={option.value}>
                                            {option.label}
                                        </option>
                                    ))}
                                </select>
                                </div>
                            </div>
                            </div>

                            <div className="col-md-12 mt-4  mb-5">
                                <div className="row">
                                    <div className="col-md-12 ">
                                        <h2 className='p-1 '>Latest Minimum Wages in {minState} and {minact}</h2>
                                    </div>
                                    {/* <div className="col-md-12 mb-3">
                                    <select className="col-md-6 sm-4 mb-3 form-select" value={selectedValue} onChange={handleChange}>
                                        {options.map(option => (
                                            <option className='col-md-6 sm-4' key={option.value} value={option.value}>
                                                {option.label}
                                            </option>
                                        ))}
                                    </select>
                                    </div> */}
                                    <div className="table-responsive p-0 wageTable" style={{ maxHeight:"400px" }}>
                                        {/* <div className="card-header">
                                            <div className="row pt-1">
                                                <h6 className='m-0 col-md-12 d-flex'>
                                                    <p className='col-md-1 pb-0 mb-0 pt-2'>Select Act:</p>
                                                    <p className='col-md-3 pb-0 mb-0'>
                                                        <select className="col-md-6 sm-4 mb-3 form-select" value={selectedValue} onChange={handleChange}>
                                                            {options.map(option => (
                                                                <option className='col-md-6 sm-4' key={option.value} value={option.value}>
                                                                    {option.label}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </p>
                                                </h6>
                                            </div>
                                        </div> */}

                                        <table className="table table-bordered table-hover " >

                                            <thead className=" sticky-top">

                                                {

                                                    minwagesing.length >0 &&(
                                                        minwagesing.map((col,key)=>(

                                                            <tr  key={key} className='mw-header  bg-light'>
                                                                {col.category ? <th scope="col">Type Of Employee</th> : null}
                                                                {col.zone ? <th scope="col">Zone</th> : null}
                                                                {col.mw_basic ? <th scope="col">Basic</th> : null}
                                                                {col.mw_da? <th scope="col">DA</th> : null}
                                                                {col.mw_hra ? <th scope="col" >HRA</th> : null}
                                                                {col.total_mw ? <th scope="col">Total</th> : null}
                                                            </tr>

                                                        ))
                                                    )

                                                }
                                                {
                                                    minwagesing.length ==0 &&(
                                                        <tr>
                                                           <div className="col-md-12 bg-light  mindatanotfound">
                                                                {/* <div className='mw-header minimunNDF'>
                                                                    <img src={nodatafound} alt="" />
                                                                </div> */}
                                                                <h5 className='text-center text-dark'>
                                                                    Contact <a href="mailto: leads@accunityhr.com"> leads@accunityhr.com</a> for minimum wages of {minact}
                                                                </h5>
                                                           </div>
                                                        </tr>

                                                    )
                                                }


                                            </thead>
                                            <tbody >
                                                {
                                                    minwagesing.length >0 &&(
                                                        minwagesing.map((col,key)=>(
                                                            <tr  key={key} >
                                                                {col.category ? <td>{col.category}</td> : null}
                                                                {col.zone ? <td>{col.zone}</td> : null}
                                                                {col.mw_basic ? <td>{col.mw_basic}</td> : null}
                                                                {col.mw_da? <td>{col.mw_da}</td> : null}
                                                                {col.mw_hra ? <td>{col.mw_hra}</td> : null}
                                                                {col.total_mw ? <td>{col.total_mw}</td> : null}

                                                            </tr>

                                                        ))
                                                    )
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default MinwageSingledata;
