import React from 'react'
import "./Loader.css"
import 'animate.css';
import celebrate from "../Component/asset/ezgif.com-crop.gif"
import theimage from "../Component/asset/9year_img-removebg-preview .png"

function Loader() {
  return (
    <div className="container-fluide">
    <div className="fullscreen-container">
    <h1 className='text-light immmmm animate__animated animate__backInDown'><img src={theimage} alt="" /></h1>
</div>
</div>
  )
}

export default Loader
