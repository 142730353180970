import React,{useState,useEffect} from 'react'
import "./footer.css"
import { FaTwitterSquare, FaFacebookSquare, FaInstagramSquare } from 'react-icons/fa';
import { BsLinkedin } from "react-icons/bs";
// import { FaXTwitter } from "react-icons/fa6";
// import { FaTwitter } from "react-icons/fa";
import { RiTwitterXLine } from "react-icons/ri";

import AccunityHRlogo from "../asset/file.png"


function Footer() {
    const [isVisible, setIsVisible] = useState(false);
    useEffect(() => {
        const timer = setTimeout(() => {
          setIsVisible(true);
        }, 1000);
        return () => clearTimeout(timer);
      }, []);
  return (
    <div class="row p-0 ">
        {isVisible ? (
        <div className="col-md-12  footer p-0">
            <div className="row">
                <div className="col-md-12 mt-2">
                    <div className="row ms-5">
                        <div className="col-md-6 px-3">
                            <div className="row">
                                <div className="col-md-12 ps-4">
                                    <h5><a className="navbar-brand" href="/"><img  className="foolterImg"src={AccunityHRlogo} alt="" /></a></h5>
                                    <p className='footertext pe-5 me-2'>Accunity HR service organizations on HR compliance, payroll, legal and statutory compliance section. ACCUNITY HR is here to help and assist the employers to adhere to all the legislations in terms of Labour Acts, assortment of payroll solution, payroll administration, Employee Benefit Administration, Tax Planning, Flexible Benefits/Compensation, Business reimbursement administration, Income tax filing, etc.
                                    </p>
                                </div>
                            </div>

                        </div>
                        {/* <div className="col-md-4 px-3">
                            <div className="row">
                                <div className="col-md-12">
                                    <h5 className=' mainheader ps-3 mt-1'><span className='underline-on-hover footertext1'>Services</span></h5>
                                    <ul className='contact-list'>
                                        <li>
                                            <a href="/service/payroll" className='footertextatag'>LABOUR LAW COMPLIANCE</a>
                                        </li>
                                        <li>
                                            <a href="/service/payroll" className='footertextatag'>STATUTORY COMPLIANCE</a>
                                        </li>
                                        <li>
                                            <a href="/service/payroll" className='footertextatag'>PAYROLL OUTSOURCING</a>
                                        </li>
                                        <li>
                                            <a href="/service/staffing" className='footertextatag'>STAFFING SOLUTIONS</a>
                                        </li>
                                        <li>
                                            <a href="/service/training" className='footertextatag'>TRAINING AND DEVELOPMENT</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div> */}
                        {/* <div className="col-md-4 px-3">
                            <div className="row">
                                <div className="col-md-12">
                                    <h5 className=' mainheader ps-3'><span className='underline-on-hover'>Links</span></h5>
                                    <ul className='contact-list'>
                                        <li>
                                            <a href="/library/minimum-wages" className='footertextatag'>Minimum Wages</a>
                                        </li>
                                        <li>
                                            <a href="/service/payroll" className='footertextatag'>labour Law Compliance</a>
                                        </li>
                                        <li>
                                            <a href="/service/payroll" className='footertextatag'>Statutory Compliance</a>
                                        </li>
                                        <li>
                                            <a href="/service/payroll" className='footertextatag'>Payroll Outsourcing</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div> */}
                        <div className="col-md-6 px-3">
                            <div className="row">
                                <div className="col-md-12 ps-5 pe-5">
                                    <h6 className='underline-on-hover mainheader mt-1'>Contact Us</h6>
                                    <p className='footertext  mb-1'>Accunity HR Services Private Limited. <br /> Krishh Towers 9, BEML Layout, Channasandra,<br /> Rajarajeshwari Nagar, Bengaluru 560098</p>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className="col-md-6">

                                    <p className='footersubheader m-0'>Contact Number</p>
                                    <a href='tel:9886434344' className='footertextatag'>9886-434-344</a>
                                                </div>
                                                <div className="col-md-6">

                                    <h6 className='footersubheader m-0'>Mail ID</h6>
                                    <a href="mailto: leads@accunityhr.com" className='footertextatag'> leads@accunityhr.com</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <h6 className='footersubheader m-0'>Technical Support</h6>
                                    <a href="mailto: leads@accunityhr.com" className='footertextatag'> leads@accunityhr.com</a> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="row">
                <div className="col-md-6 text-center text-dark mt-4">
                <p className='copyright sm-ms-0'>© 2023 Copyright:AccunityHR</p>
                </div>
                <div className="col-md-6 social-media-btn text-center">
                    <a href="https://x.com/AccunityHr" className='px-2 twitter'><FaTwitterSquare /></a>
                    <a href="https://www.facebook.com/accunityhrservices"className='px-2 facebook'><FaFacebookSquare /></a>
                    <a href="https://www.instagram.com/accunityhr"className='px-2 insta'><FaInstagramSquare /></a>
                </div>
            </div> */}
            <div className="row">
                <div className="col-md-12 text-center text-dark mt-1">
            <hr  className='mx-5 text-bolder footertext1'/>
                <p className='copyright text-center footertext1 m-0'>© 2024 Copyright:AccunityHR</p>
                </div>
                <div className="col-md-12 social-media-btn text-center">
                    <a href="https://www.linkedin.com/company/accunity-hr-services-pvt--ltd-/" className='px-2 twitter'>
                        {/* <FaTwitterSquare /> */}
                        <BsLinkedin />
                        </a>
                    <a href="https://www.facebook.com/accunityhrservices"className='px-2 facebook'><FaFacebookSquare /></a>
                    <a href="https://www.instagram.com/accunityhr"className='px-2 insta'><FaInstagramSquare /></a>
                </div>
            </div>
        </div>
      ) : (
        <div style={{ display: 'none' }}>
        </div>
      )}

  </div>
  )
}

export default Footer
