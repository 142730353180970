import React,{useState} from 'react'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';


import './nav.css'
import AccunityHRlogo from "../asset/AccunityHRlogo.png"

function Menu() {
  const [navbar,Setnavbar]=useState(false)
  // window.addEventListener('resize',show)
  const changeBackground=()=>{
    // console.log(window.scrollY)
    if(window.scrollY >50){
      Setnavbar(true)
    }else{
      Setnavbar(false)
    }


  }
  window.addEventListener('scroll', changeBackground);
  const [btnClass, setBtnClass] = useState("blue-color");

        function toggleColor() {
          setBtnClass(
            btnClass === "blue-color" ? "orange-color" : "blue-color"
          );
        }


  return (
    <div className='className={`${btnClass}`}'>
    <nav id='nav-bar'  className={navbar?"navbar navbar-expand-lg nav-bar active":"navbar nav-bar navbar-expand-lg"}>

  <a className="navbar-brand" href="/"><img  className="img_1"src={AccunityHRlogo} alt="" /></a>


  <button onClick={toggleColor} className="navbar-toggler " type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
    <span className="navbar-toggler-icon"></span>
  </button>
  <div className="collapse navbar-collapse justify-content-end" id="navbarNavDropdown">
    <ul className="navbar-nav mb-2 mb-lg-0">
        <li className="nav-item">
            <a className="nav-link text-dark" href="/">HOME</a>
        </li>
        <li className="nav-item">
            <a className="nav-link text-dark" to="source" href="/about"> ABOUT US</a>
        </li>
        <li className="nav-item">
            <div className="dropdown ">
                <button className="btn dropdown-toggle border-0 pt-2" type="button" id="dropdownMenu2" data-bs-toggle="dropdown" aria-expanded="false">
                    SERVICES
                </button>
                <ul className="dropdown-menu text-center" aria-labelledby="dropdownMenu2">
                    <li><button className="dropdown-item" type="button" ><a className="dropdown-item" href="/service/labour">Labour Law Compliance</a></button></li>
                    <li><button className="dropdown-item" type="button" ><a className="dropdown-item" href="/service/statutory">Statutory Compliance</a></button></li>
                    <li><button className="dropdown-item" type="button"> <a className="dropdown-item" href="/service/payroll">Payroll Outsourcing Services</a></button></li>
                    <li><button className="dropdown-item" type="button"><a className="dropdown-item" href="/service/staffing">Staffing Solutions</a></button></li>
                    <li><button className="dropdown-item" type="button"><a className="dropdown-item" href="/service/consulting">HR Consulting</a></button></li>
                    <li><button className="dropdown-item" type="button"><a className="dropdown-item" href="/service/training">Training & Development</a></button></li>
                </ul>
            </div>
        </li>
        <li className="nav-item">
            <div className="dropdown">
                <button className="btn dropdown-toggle border-0 pt-2" type="button" id="dropdownMenu2" data-bs-toggle="dropdown" aria-expanded="false">
                    LIBRARY
                </button>
                <ul className="dropdown-menu text-center" aria-labelledby="dropdownMenu2">
                    <li><button className="dropdown-item" type="button" ><a className="dropdown-item" href="/library/minimum-wages">Minimum wages</a></button></li>
                </ul>
            </div>
        </li>


        <li className="nav-item">
            <a className="nav-link text-dark dcdf" href="/contact">CONTACT</a>
        </li>

    </ul>
  </div>


</nav>
</div>
  )
}

export default Menu
