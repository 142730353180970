import React from 'react'
import "./Training.css"
import trainingimgbanar from "../../asset/services/training/training-banar.webp"
import trainingimg1 from "../../asset/services/training/training-1.webp"
import trainingimg2 from "../../asset/services/training/training-2.webp"

function Training() {
  return (
    <div className="container-fuide">
        <div className="container-fluid trainingheader-main p-0" style={{ backgroundColor:`url(${trainingimgbanar})`}}>
            <div className=" trainingheader-11 pt-5 mt-5">
                <div className="trainingheadcont p-5 mt-5 ">
                <h1> TRAINING AND DEVELOPMENT</h1>
                <h5>"Training and Development is never ending process"</h5>
                </div>
            </div>
        </div>
    <div className="clas-md-12 px-3 my-3 traininghead2">
        <div className="col-md-12 text-center py-4">
        <h1 className='text-uppercase'> Training and Development</h1>
        </div>
        <div className="row justify-content-center">
                        <div className="col-md-12 text-center my-4">
                        <img className='trainingaddbene' src={trainingimg1} id='trainingaddbene' alt=" no image"  />
                    </div>
                    <div className="col-md-11  ">
                        <p>
                        Training and development involve improving the effectiveness of organizations and the individuals and teams within them. Training may be viewed as related to immediate changes in organizational effectiveness via organized instruction, while development is related to the progress of longer-term organizational and employee goals. Training and development have historically been a topic within applied psychology but has within the last two decades become closely associated with human resources management, talent management, human resources development, instructional design, human factors, and knowledge management. Accunity HR offers wide range of training and development program to our clients. Some of them are listed herein. <br /><br />
                        <ul style={{ listStyleType: "circle" }}>
                            <li>Labour Law Compliance and requirements</li>
                            <li>Human Resource team must know</li>
                            <li>Voice And Ascent</li>
                            <li>Behavioural Training</li>
                            <li>Time Management</li>
                            <li>Motivation</li>
                            <li>Change Management</li>
                            <li>Teamwork</li>
                            <li>Leadership Skill</li>
                            <li>Conflict Management</li>
                            <li>Group Discussion & Public Speaking</li>
                            <li>Presentation Skills</li>
                            <li>Certification Course – Labour Law Compliance</li>
                            <li>Team Budling</li>
                            <li>Interpersonal Skill</li>
                            <li>Body Language</li>
                            <li>Excel, Shortcuts, Macros</li>
                            <li>Complex Macros & VBA</li>
                        </ul>
                        </p>

                    </div>
        </div>


    </div>
    <div className="clas-md-12 px-3 my-3 traininghead2">
        <div className="col-md-12 text-center py-4">
        <h1 className='text-uppercase'> Employee Wellness Programme</h1>
        </div>
        <div className="row justify-content-center">
                        <div className="col-md-12 text-center my-4">
                        <img className='trainingaddbene' src={trainingimg2} id='trainingaddbene' alt=" no image"  />
                    </div>
                    <div className="col-md-11  ">
                        <p>
                        Wellness not only means taking care of the body but the mind as well provides confidential support for issues like stress, substance abuse, depression, and anxiety. Accenture knows a lot of work-related stress can be addressed outside of the workplace through guided support programs, so they provide access to programs, such as financial assistance, for all of their employees. Employees are also rewarded for setting wellness goals and accomplishing them.
                        </p>
                        <p>
                        Employees who need extra assistance outside the workplace get the help they need to perform their best. Companies like Accenture realize that support for their employees shouldn't end once they leave the building. By providing extra support initiatives, organizations can communicate a certain level of empathy and support for their people, thus boosting satisfaction and confidence between the employer and the employee.
                        </p>
                        <p>
                        Accunity HR have experienced experts of life skill counsellors. We help your employees to assess their problems and providing solutions by counselling them, that shall boost the employee’s self confidence and help organization to have better work force who deliver their best work
                        </p>

                    </div>
        </div>


    </div>
    </div>
  )
}

export default Training
