import React, { useState } from 'react';
// import ChatBot from 'react-chatbot';

function ChatbotComponent() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleChatbot = () => {
    setIsOpen(!isOpen);
  };

  return (
    // <div>
    //   <button onClick={toggleChatbot}>Open Chatbot</button>
    //   {isOpen && (
    //     <ChatBot
    //       steps={[]}
    //       // Define chatbot steps or conversation flow here
    //     />
    //   )}
    // </div>
    <div>
        
    </div>
  );
}

export default ChatbotComponent;
