import React, { useEffect, useState } from 'react';
import TestiMonialsDetails from './Testimonial2'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import userPic from '../asset/BPO.png';
// import quatation from "../asset/quotations.png"
import './Testimonial1.css'

const TestiMonials = () => {

    const testiMonials = [
        {
            name: 'Rajashree Mukherjee',
            description: 'I am writing to thank you for the quality of service provided by Accunity HR Services Pvt Ltd. We sincerely appreciate your efficient, gracious customer service,We have, and will continue to, recommend your service to other companies and contacts. Our team could not be more satisfied with your work, and we look forward to continuing this relationship.',
            address: 'Director, VMC Technologies Pvt Ltd',
            // img: 'https://i.ibb.co/hgGJc8d/Gareth-Bale.jpg'
        },
        {
            name: 'Tavamani Gracy',
            description: 'We have been associated with Accunity for close to three years. In this span of three years, their service has been very much satisfactory.  S & E Registrations and Renewals have been done on time .  Their support on negotiation with Government officials, consultations during any notifications was very good.  They have always taken the ownership of all compliance related activities and ensured everything is done on time.Great and Quality Service rendered by them. Sincere thanks to all.',
            address: 'VP, HR & ADMIN VISAGE HOLDINGS & FINANCE PRIVATE LIMITED',
            // img: 'https://i.ibb.co/z7Kp6yr/np-file-33188.jpg'
        },
        // {
        //     name: 'Steve Burns',
        //     // colon: {quatation},
        //     description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s.',
        //     address: 'USA',
        //     // img: 'https://i.ibb.co/CP5sj7g/2856040-58866808-2560-1440.jpg'
        // },
        // {
        //     name: 'Kevin Canlas',
        //     // colon: {quatation},
        //     description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s.',
        //     address: 'USA',
        //     // img: 'https://i.ibb.co/10SYccm/1552313010-354215-noticia-normal.jpg'
        // },
    ]
    //Owl Carousel Settings
    const options = {
        loop: true,
        center: true,
        items: 3,
        margin: 0,
        autoplay: true,
        dots: true,
        autoplayTimeout: 2000,
        smartSpeed: 450,
        autoplayHoverPause: true,
        nav: false,

        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 3
            },
            1000: {
                items: 3
            }
        }
    };
    return (
        <section id="testimonial" className="testimonials pb-5 " style={{ backgroundColor:"green" }}>
            <div className="container mt-5">
                <h1 className="miniTitle text-center pt-5 ">TESTIMONIALS</h1>
                <div className="text-center ">
                    <h3 className="sectionTitle">What Our Clients are Saying?</h3>
                </div>
                <div className="row" >
                    <div className="col-md-12">
                        <OwlCarousel id="customer-testimonoals" className="owl-carousel owl-theme" {...options}>
                            {
                                testiMonials.length === 0 ?
                                    <div class="item">
                                        <div class="shadow-effect">
                                            {/* <img class="img-circle" src={userPic} /> */}

                                            <p>We have been associated with Accunity for close to three years. In this span of three years, their service has been very much satisfactory.  S & E Registrations and Renewals have been done on time .  Their support on negotiation with Government officials, consultations during any notifications was very good.  They have always taken the ownership of all compliance related activities and ensured everything is done on time.Great and Quality Service rendered by them. Sincere thanks to all.</p>
                                        </div>
                                        <div class="testimonial-name">
                                            <h5>Tavamani Gracy</h5>
                                            <small>VP, HR & ADMIN VISAGE HOLDINGS & FINANCE PRIVATE LIMITED</small>
                                        </div>
                                    </div> :
                                    testiMonials.map(testiMonialDetail => {
                                        return (
                                            <div className='backshade'>
                                            <TestiMonialsDetails testiMonialDetail={testiMonialDetail} key={testiMonialDetail._key} />
                                            </div>

                                        )
                                    })
                            }
                        </OwlCarousel>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default TestiMonials;
