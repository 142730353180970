// Modal.js
import React from 'react';
import "./Registration.css"
import xxxx from "../asset/xxxxx.png"
import Registration from './Registration';
function Modal({ show, onClose, children }) {
  if (!show) return null;

  return (
    <div className='bod'>
    <div className="modal-backdrop ">

      <div className="modal d-block">
        <div className="modal-dialog">
          <div className="modal-content mt-3 ">
            <div className="modal-header py-2 ">
                <h4 className='ps-2'>Register for Demo</h4>
              <button type="button " className="close justify-content-end" onClick={onClose}>
                <img src={xxxx} alt=""  />
              </button>
            </div>
            <div id='reddd' className="modal-body">
              {/* {children} */}
              <Registration onClose={onClose}/>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
}

export default Modal;
