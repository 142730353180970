import React ,{useState}from 'react'
import "./Contact.css"
import{FiPhoneCall} from "react-icons/fi"
import{GrLocation}from "react-icons/gr"
import{MdEmail}from"react-icons/md"
import axios from 'axios'
import contactworld from "../asset/contacttttt.gif"
import Sweetalert from '../Sweetalert/Sweetalert';

function Contact() {
    const [showAlert, setShowAlert] = useState(false);
    const contactFormData = {
        companyName: '',
        name: '',
        email: '',
        phone: "",
        message: ''
    };
    const [formData, setFormData] = useState(contactFormData);
    const [error, setError] = useState({});
    const validateEmail = (email) => {
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
        return emailRegex.test(email);
    }
    const validation=()=>{
        let tempErrors = {};
        if (!formData.phone.trim()) tempErrors.phone = "Phone Number is required";
        if (!formData.message.trim()) tempErrors.message = "Job Title is required";
        if (!formData.companyName.trim()) tempErrors.companyName = "Company Name is required";
        if (!formData.name.trim()) tempErrors.name = " Name is required";
        if (!formData.email.trim()) {
            tempErrors.email = "Email is required";
        } else if (!validateEmail(formData.email)) {
            tempErrors.email = "Invalid email format";
        }
        setError(tempErrors);

        return Object.keys(tempErrors).length === 0;
    }
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
      };
    const formsubmit = async (e) => {
        e.preventDefault();

        if (validation()) {
            try {
                const response = await axios.post('https://websitenode.accunityhr.com/contact', formData, {
                  headers: {
                    "Content-Type": "application/json"
                  }
                });
                console.log('Data stored successfully:', response.data);
                setFormData(contactFormData);
                setShowAlert(true);

              } catch (error) {
                console.error('Error storing data:', error);
              }

        }
        }
    const handleCloseAlert = () => {
        // Close the SweetAlert when needed
        setShowAlert(false);
        };
  return (
    <div id='contact' className='containerrr'  style={{ height:"auto",position:"relative"}}>

      <div className="container-lg">
      <div className='head'>
        <h5 className='head_1'>CONTACT US</h5>
        <h1 className='heade_2'>Get in touch with us</h1>
      </div>
        <div className='contact'>

          <div className="contact_1">
            <h1><i><FiPhoneCall/>   </i>Contact</h1>
            <a href="tel:080-4228-6717">080-4228-6717</a>
          </div>
          <div className="contact_1">
            <h1><i><GrLocation/></i>Location</h1>
            <a href="https://www.google.com/maps/dir//Krishh+Towers+9,+BEML+Layout,+Channasandra,+Rajarajeshwari+Nagar,+Bengaluru,+Karnataka+560098/@12.906588,77.430337,12.04z/data=!4m8!4m7!1m0!1m5!1m1!1s0x3bae3f75557cb365:0x6b28c6a45a5aa91c!2m2!1d77.5126067!2d12.9050599?entry=ttu"> Bangalore karnataka,INDIA</a>
          </div>
          <div className="contact_1">
            <h1><i><MdEmail/></i>Email</h1>
            <a href="mailto: leads@accunityhr.com"> leads@accunityhr.com</a>
          </div>
        </div>
        <div className="container-con-1 bg-white">
        {/* <h2 className='pt-5'>Contact Us</h2> */}
        <div className="container-con">
          <div className='formm'>
            <h2>Contact Us</h2>
            <form onSubmit={formsubmit}>
            <div className="mb-3 form-group">
            <input type="text" className="form-control" id="name" value={formData.name} onChange={handleInputChange}  placeholder=' Enter your name' name='name'/>
            {error.companyName && <p className="error">{error.name}</p>}
            </div>
            <div className="mb-3 form-group">
            <input type="text" className="form-control" id="companyName" value={formData.companyName} onChange={handleInputChange}  placeholder=' Enter your company name' name='companyName'/>
            {error.name && <p className="error">{error.companyName}</p>}
            </div>

            <div className="mb-3 form-group">
            <input type="email" className="form-control" value={formData.email}
                onChange={handleInputChange} id="exampleInputEmail1" placeholder='business Email    Ex:user@companyname.com'  name='email'/>

                {error.email && <p className="error">{error.email}</p>}
            </div>
            <div className="mb-3 form-group">
            <input  type='tel'  className="form-control" placeholder='Contact number' value={formData.phone}
                onChange={handleInputChange} name='phone' />
                {error.phone && <p className="error">{error.phone}</p>}
            </div>

            <div className="mb-3 form-group">
            <textarea name="message" className="form-control" value={formData.message}
                onChange={handleInputChange} placeholder='please enter your message' id="" cols="40" rows="3"></textarea>
                {error.message && <p className="error">{error.message}</p>}
            </div>

            <button type="submit" className="btn btn-primary">Submit</button>
      </form>
            <Sweetalert
                show={showAlert}
                type="success" // or 'error', 'warning', etc.
                title="Thank you"
                text="Our Team Will Contact You."
                onClose={handleCloseAlert}

            />
      </div>
                <div className='formm_1 '>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3889.041312410556!2d77.51003177377008!3d12.905065116336143!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae3f75557cb365%3A0x6b28c6a45a5aa91c!2sAccunity%20HR%20Services%20Private%20Limited!5e0!3m2!1sen!2sin!4v1691736754586!5m2!1sen!2sin" wstyle={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                {/* <img className='mt-5' width={"700vw"} height={"500vh"}  src={contactworld} alt="" /> */}
                </div>
                </div>
              </div>
              {/* <div className="contactImg">
                <img  src={contactworld} alt="" />
              </div> */}
              </div>
    </div>


  )
}

export default Contact
