import React from 'react'
import "./Staffing.css"
import mainstaff from "../../asset/Staffing-banar.jpeg"
import staff1 from "../../asset/staffing-1.png"
import staff2 from "../../asset/staffing.jfif"
import staff3 from "../../asset/benefit-staffing.png"

function Staffing() {
  return (
    <div className="container-fluid">
        <div className="row">
            <div className="container-fluid stafff-head-1" >
                <div className="row stafff-head-1col pt-5">
                    <div className="col-md-12 mt-5 text-center stafff-head-1col-data">
                        <h1 className='text-uppercase'>Staffing Solutions</h1>
                        <h5>"Opportunities Don't Happen.You Create Them"</h5>
                    </div>
                </div>
            </div>
            <div className="col-md-12 staff-head-2">
                <div className="row justify-content-center">
                    <div className="col-md-12">
                        <h1 className="text-center my-4 statfff-cont-data-head text-uppercase">
                            Contract Staffing
                        </h1>
                    </div>
                    <div className="col-md-11">
                        <div className="row">
                            <div className="col-md-6 ">
                                <div className="row staff-head-img ">
                                    <img src={staff1} alt="" />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="row">
                                <div className="col-md-12 pt-2">
                                    <p>
                                    Contract staffing as the name speaks is a method of recruiting required human resource on fixed term contract. This type of arrangement is commonly used when short term manpower arise due to sudden increase of production load. Or when the non-core work is required. Contract Staffing helps organizations to quickly ramp up project teams with as few or many qualified consultants as are required to fill short-term and long-term needs.
                                    </p>
                                </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div className="col-md-12 staff-head-3">
                <div className="row justify-content-center">
                <div className="col-md-12">
                        <h1 className="text-center my-4 statfff-cont-data-head text-uppercase">
                        Why Contract Staffing ?
                        </h1>
                    </div>
                    <div className="col-md-11">
                        <div className="row">
                            <div className="col-md-6 staffing-display-1">
                                <div className="row staff-head-img d-flex justify-content-center mt-2">
                                    <img src={staff2} alt="" />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="row">
                                    <div className="col-md-12">
                                        <p>
                                            The contingent employment industry is traditionally a leading indicator of post-recession economic conditions and a reliable predictor of future employment trends. Cautious employers hire temps first, hedging their bets on a recovery, recognizing it is easier to scale back if demand does not materialize. This cycle is no different, except that this time employers plan to maintain a larger portion of their workforce as contract employees even after business recovers.
                                            During the recession, employers learned to refocus on their core business, realizing that a smaller core workforce that was well trained and technologically astute was more effective and nimble than their pre-recession staff. As firms emerge from the recession they are, of course, beginning to hire full time workers but they are also seeing a larger role for highly skilled contract workers who are engaged on an as-needed basis.
                                        </p>
                                    </div>

                                </div>
                            </div>
                            <div className="col-md-6 staffing-display-2">
                                <div className="row mt-4 staff-head-img d-flex justify-content-end mt-2">
                                    <img src={staff2} alt="" />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div className="col-md-12 staff-head-4 mb-5">
                <div className="row justify-content-center">
                <div className="col-md-12">
                        <h1 className="text-center my-4 statfff-cont-data-head text-uppercase">
                        Benefit to have contract resources on third party role
                        </h1>
                    </div>
                    <div className="col-md-11">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="row staff-head-img">
                                <img src={staff3} alt="" />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="row">
                                    <div className="col-md-12">
                                        <p className='mt-3'>
                                        The company can get rid from hassle of maintaining the registers, records and statutory remittances for these contract employees. Processing the payroll, handling statutory deductions and remittances like provident fund, employee state insurance, income tax, professional tax, etc, are the most complicated activity, that require skilled resource to manage it properly. When the company hires employees Accunity HR’s role, we shall take care of all these requirements and the company can focus on their core business.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Staffing
