import React from 'react'
import "./HRconsultent.css"
import hr1 from "../../asset/HR-consultent.jpg"
import hr2 from "../../asset/HR-consultent-1.jpg"
import hr3 from "../../asset/HR-consultent-2.jpg"

function HRconsultent() {
  return (
    <div className="container-fluid">
        <div className="row">
            <div className="container-fluid hring-head-1 " >
                <div className="row hring-head-1col pt-5">
                    <div className="col-md-12 mt-5">
                        <div className="col-md-8 mt-5 ms-5 pt-5 hring-head-1col-data">
                        <h1 className=''>HR Consulting</h1>
                        <h5>"Nothing we do more important than hiring people. At the end of the day you bet on people  not Strategies"</h5>

                        </div>

                    </div>
                </div>
            </div>
            <div className="col-md-12 hring-head-2">
                <div className="row justify-content-center">
                    <div className="col-md-12">
                        <h1 className="text-center hring-cont-data-head my-4 text-uppercase">
                            Human Resources Consulting
                        </h1>
                    </div>
                    <div className="col-md-11">
                        <div className="row">
                            <div className="col-md-6 ">
                                <div className="row hring-head-img ">
                                    <img src={hr1} alt="" />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="row">
                                <div className="col-md-12 pt-2">
                                    <p>
                                    Human resource management is the most sensitive and important role in any organization. Because, human resource is the main pillar of business. The mismanagement of human resource may lead to business collapse.
                                    In the meanwhile, it is very much necessary to have the proper policies, procedures and guidelines to provide industry standard benefits and on other hand handle all types of employees’ queries and behaviours.

                                    </p>
                                </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div className="col-md-12 hring-head-3">
                <div className="row justify-content-center">
                <div className="col-md-12">
                        <h1 className="text-center my-4">
                        </h1>
                    </div>
                    <div className="col-md-11">
                        <div className="row">
                            <div className="col-md-6 staffing-display-1">
                                <div className="row hring-head-img d-flex justify-content-center mt-2">
                                    <img src={hr2} alt="" />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="row">
                                    <div className="col-md-12">
                                        <p>
                                        We Accunity HR is help organizations in designing, preparing and managing all necessary policies and guidelines, handling employee quires and handling and resolving disputes. Further we help the HR team in handling situations such as employee absconding, employees’ non-cooperation, etc.
                                        Managing Employee file is very important task. It is highly important to collect and maintain several documents such as statutory forms like Form-11, Form-2, Form-F and employee related document like Aadhaar card, PAN card, educational certificate, resume, etc. Accunity HR shall conduct employee file audit and help and guide the organization on missing documents and the available solutions to mitigate the non-compliance.

                                        </p>
                                    </div>

                                </div>
                            </div>
                            <div className="col-md-6 staffing-display-2">
                                <div className="row hring-head-img d-flex justify-content-end mt-2">
                                    <img src={hr2} alt="" />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div className="col-md-12 hring-head-4 mb-5">
                <div className="row justify-content-center">
                <div className="col-md-12">
                        <h1 className="text-center my-4">
                        </h1>
                    </div>
                    <div className="col-md-11">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="row hring-head-img">
                                <img src={hr3} alt="" />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="row">
                                    <div className="col-md-12 mt-4">
                                        <p>
                                        Accunity HR helps the organization on drafting and reviewing (if already exists) all types of employee agreements, vendor agreements and any type of legal agreements.
                                        When the organization have multiple branches, multiple business units, it is wise to have centralised HR team to handle the entire human resources in various business units. Accunity HR is expert in creating HR shared service team, which is not only work effective, but also cost effective to the organization.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default HRconsultent
