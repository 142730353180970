// // RegistrationForm.js
import React, { useState ,useEffect} from 'react';
import axios from 'axios'
import Sweetalert from '../Sweetalert/Sweetalert';


function Registration({onClose}) {
    const [showAlert, setShowAlert] = useState(false);
    const initialFormData = {
        firstName: "",
        lastName: "",
        email: '',
        phone: "",
        jobTitle: '',
        companyName: '',
        payroll: true,
        compliance: false,
        city: ""
    };
    const [formData, setFormData] = useState(initialFormData);

    const [errors, setErrors] = useState({});
    const validateEmail = (email) => {
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
        return emailRegex.test(email);
    }

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: type === 'checkbox' ? checked : value
        }));
    }

    const validate = () => {
        let tempErrors = {};

        if (!formData.firstName.trim()) tempErrors.firstName = "First Name is required";
        if (!formData.lastName.trim()) tempErrors.lastName = "Last Name is required";
        if (!formData.phone.trim()) tempErrors.phone = "Phone Number is required";
        if (!formData.jobTitle.trim()) tempErrors.jobTitle = "Job Title is required";
        if (!formData.companyName.trim()) tempErrors.companyName = "Company Name is required";
        if (!formData.email.trim()) {
            tempErrors.email = "Email is required";
        } else if (!validateEmail(formData.email)) {
            tempErrors.email = "Invalid email format";
        }
        if (!formData.city.trim()) tempErrors.city = "City is required";
        if (!(formData.payroll || formData.compliance)) tempErrors.checkbox = "Please select at least Payroll or Compliance";

        setErrors(tempErrors);

        return Object.keys(tempErrors).length === 0; // returns true if no errors
    }
    useEffect(() => {

    }, []);


    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validate()) {
            try {
                const response = await axios.post('https://websitenode.accunityhr.com/register', formData,{
                    headers : {
                        "Content-Type": "application/json"
                    }
                });
                setFormData(initialFormData)
                setShowAlert(true);

              } catch (error) {
                console.error('Error storing data:', error);
              }
    }

    }
    const handleCloseAlert = () => {
        setShowAlert(false);
        // window.location.reload();
        onClose();
      };
return (
    <div className="container mt-2">
        <form onSubmit={handleSubmit} className='regform'>
        <div className="form-group userName">
            <div>
            <input
                name="firstName"
                className="form-control"
                value={formData.firstName}
                onChange={handleInputChange}
                placeholder="First Name"
            />
            {errors.firstName && <p className="error text-danger">{errors.firstName}</p>}
            </div>
            <div>
            <input
                name="lastName"
                className="form-control"
                value={formData.lastName}
                onChange={handleInputChange}
                placeholder="Last Name"
            />
            {errors.lastName && <p className="error text-danger">{errors.lastName}</p>}

            </div>
        </div> <br />
        <div className="form-group">
            <input
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                className="form-control"
                placeholder="Email"
            />
            {errors.email && <p className="error text-danger">{errors.email}</p>}
        </div> <br />
        <div className="form-group">
            <input
                name="companyName"
                value={formData.companyName}
                className="form-control"
                onChange={handleInputChange}
                placeholder="Company Name"
            />
            {errors.companyName && <p className="error text-danger">{errors.companyName}</p>}
        </div><br />
            <div className="form-group">
            <input
                name="phone"
                className="form-control"
                value={formData.phone}
                onChange={handleInputChange}
                placeholder="phone number"
            />
            {errors.phone && <p className="error text-danger">{errors.phone}</p>}
            </div><br />
            <div className="form-group userdata">
            <div className="form-group">
            <input
                name="jobTitle"
                value={formData.jobTitle}
                className="form-control"
                onChange={handleInputChange}
                placeholder="Job Title"
            />
            {errors.jobTitle && <p className="error text-danger">{errors.jobTitle}</p>}
            </div><br />
            <div className="form-group">
            <input
                name="city"
                value={formData.city}
                className="form-control"
                onChange={handleInputChange}
                placeholder="City"
            />
            {errors.city && <p className="error text-danger">{errors.city}</p>}

            </div>
            </div> <br />
            <div className="form-group">
                <div className="form-check form-check-inline">
                <label>
                <input
                    className="form-check-input"
                    type="checkbox"
                    name="payroll"
                    checked={formData.payroll}
                    onChange={handleInputChange}
                />
                Payroll
            </label>
                </div>
                <div className="form-check form-check-inline">
                <label>
                <input
                    className="form-check-input"
                    type="checkbox"
                    name="compliance"
                    checked={formData.compliance}
                    onChange={handleInputChange}
                />
                Compliance
            </label>
                </div>

            </div>



            {errors.checkbox && <p className="error">{errors.checkbox}</p>}

            <button type="submit" className="btn btn-primary">Register</button>
        </form>
        <Sweetalert
        show={showAlert}
        type="success" // or 'error', 'warning', etc.
        title="Thank you"
        text="Our Team Will Contact You."
        onClose={handleCloseAlert}

      />
    </div>

);
}

export default Registration;
