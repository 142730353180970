import React,{useState ,useEffect} from 'react'
import "./MinumumWages.css"
import axios from 'axios'
import Select from 'react-select'
function MinimumWages() {
    const [minimwages,setminimwages]=useState();
    const [options,setOptions]=useState([])
    useEffect(() => {
      fetchdata();
    }, []);
    function formatDate(dateString) {
        const date = new Date(dateString);
        const dd = String(date.getDate()).padStart(2, '0');
        // const mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const mm = months[date.getMonth()];
        const yyyy = date.getFullYear();

        return dd + '-' + mm + '-' + yyyy;
    }

        const fetchdata = async () => {
            await axios.get("https://websitenode.accunityhr.com/minimumWagesWeb")
              .then((res) => {
                  setminimwages(res.data.distinctState);
                const sdata=res.data.distinctState;
            const formattedOptions = sdata.map(state => ({
            label: state.state
        }));
        setOptions(formattedOptions);
              })
              .catch((err) => console.log(err));
          };


        if(!minimwages) return null;
        const handleChange = selectedOption => {
            var stateddata = selectedOption.label;
            window.location.href=('/library/'+stateddata+'/Shops & Commercial Establishments');
        };
        function stateClick(event){

        // Update the state with the value of the <a> tag
        const selecstate =(event.target.textContent);
            window.location.href=('/library/'+selecstate+'/Shops & Commercial Establishments');
        }



  return (
    <div className='container-fluid mw-container '>
        <div className="row justify-content-center mt-5">
            <div className=" col-md-11 mwhead-part mb-3 mt-5">

                <div className="row mx-2">
                    <h1 className='text-uppercase minhead'>Minimum Wage</h1>
                    <p>As per the Indian Constitution, 'Minimum Wage' has been defined as the level of income for skilled and unskilled workers which ensures a sustaining standard of living while also providing for some measure of comfort. A minimum wage not just supports the bare level of employment, but also seeks for viable continuous improvement. It aims at preventing exploitation of labour.</p>
                </div>
            </div>
            {/* <div className="col-md-11 my-3 mw-select d-flex justify-content-between">
                    <div className="col-md-3">
                    <Select
                    options={options}
                    onChange={handleChange}
                    isSearchable={true}

                    />
                    </div>


            </div> */}
            <div className="col-md-11 mwtable mwhead-part">
                <div className="row">
                    <div className="col-md-12 mwtablehead text-center">
                        <h1 className='text-uppercase minhead'>Minimum Wages In States Across India</h1>
                    </div>
                    {/* <hr /> */}
                    <div className="col-md-12 mwtablebody">
                        <h5 className='text-center'>The states which impose minimum wages in India are listed below: (Click on the state name for more details)</h5>
                        <div className="row">
                                <div className="col-md-12 justify-content-center">
                                    <div className="row justify-content-center ">
                                        {
                                            minimwages.length >0 &&(
                                                minimwages.map((col,key)=>(
                                                    <div className='col-md-3 m-3 p-3 stateTableBorder text-center'  key={key}>
                                                        <h5 className='mt-1'> <a className='stateLink text-dark'  onClick={stateClick} >{col.state}</a></h5>
                                                        {/* <p>Effective from : {col.eff_date}</p> */}
                                                        <p className='text-center '>Effective from : {formatDate(col.eff_date)}</p>


                                                    </div>
                                                //
                                                ))
                                            )
                                        }
                                        <div className='col-md-3 m-3 r text-center'>
                                        </div>
                                    </div>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default MinimumWages

