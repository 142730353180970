import React from 'react'
import "./LabourLaw.css"
import labourhead from "../../asset/services/labourHead.jpg"
import labour2 from "../../asset/services/labour-law-1.gif"
import labour3 from "../../asset/labourLaw11.png"
import labour5 from "../../asset/services/labour-law-1.jpg"
import labour6 from "../../asset/services/labour-law-6.png"
import labour4 from "../../asset/services/labour_law-4.png"
import listImage from "../../asset/services/labour-li-gif"

function LabourLaw() {
  return (
    <div className='container-fluid'>
        <div className="row">
        <div className="col-md-12  labourhead1" style={{ backgroundImage:`url(${labourhead})` }}>
          <div className=" row labourhead11">
            <div className="col-md-10 mt-5">
                {/* <h5>  Best labour Law compliance In India </h5> */}
                <h1 className='text-uppercase'>Labour Law compliance</h1>
                <h5> <span>Labour-Related</span> One Stop Solution</h5>
                {/* <p>"Without Labour Nothing Prospers."</p> */}
                <p>"Compliance matter's"</p>


            </div>
            </div>
        </div>
        <div className="labourhead2 col-md-12 d-flex justify-content-center">
            <div className="col-md-11 ">
                <div className="col-md-12 mt-4 text-center">
                    <h1 className='labourlawhead text-uppercase'>labour law compliance</h1>
                </div>

                <div className=' col-md-12 labourhead2body'>
                  <div className="row">
                  <div className="col-md-6 labourhead2body-img justify-content-center labour-display-1">
                    <img src={labour2} alt="" />
                  </div>
                  <div className=" col-md-6 mt-5 pt-3 labourhead2body1">
                    <p>Labour legislations regulate the employment service conditions in India. There are over fifty plus Central laws and multiple state laws created to protect the interest of the labour engaged in employment. It is mandatory to all the organisations/employers operating their business in India to adhere to the prescribed laws, acts and rules of Central and State.
                    </p>
                  </div>
                  <div className="col-md-6 labourhead2body-img justify-content-center labour-display-2">
                    <img src={labour2} alt="" />
                  </div>
                  </div>
                </div>
            </div>
        </div>
        <div className="labourhead3 col-md-12">
        <div className="row justify-content-center">
            <div className="col-md-12">
            <h1 className='text-center my-3 labourlawhead text-uppercase'>Consequences for not adhering to Labour laws</h1>
            </div>
            <div className="col-md-11">
                <div className="row">
                    <div className="col-md-12">
                        <div className="row">
                        <div className="col-md-6 d-flex  justify-content-center labourhead2body-img">
                            <img src={labour3} alt="" />
                        </div>
                        <div className="col-md-6 pt-4">
                            <p className='mt-4'>In case of the failure of adherence to labour law requirements shall cause serious consequences such as the levy of high penalties, loss of reputation, etc. There is a chance of forfeiting / cancelling the License. Most importantly, head of the company may face imprisonment punishment.</p>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
        <div className="labourhead4 col-md-12">
            <div className="row justify-content-center">
                <div className="col-md-12">
                    <h1 className='text-center my-3 labourlawhead text-uppercase'>Benefits of outsourcing Labour law compliance</h1>
                </div>
                <div className="col-md-11">
                    <div className="row">
                    <div className="col-md-6 labourhead2body-img justify-content-center labour-display-1">
                        <img src={labour4} alt="" />
                    </div>
                    <div className="col-md-6 pt-4">
                        <p>To manage Labour law compliance activities shall required skilled workforce with complete knowledge of labour law. The workforce should have knowledge about latest changes and updates. It is required to have good experience and personal reputation with authorities to liaison. Most importantly getting skilled and experience resources is very difficult. Further, cost for such resources is very high. When the Labour law compliance acetates are outsourced to Accunity HR shall address all these difficulties. </p>
                    </div>
                    <div className="col-md-6 labourhead2body-img justify-content-center labour-display-2">
                        <img src={labour4} alt="" />
                    </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="labourhead4 col-md-12">
            <div className="row">
                <div className="col-md-12">
                <h1 className='text-center my-3 labourlawhead text-uppercase'>How do Accunity HR supports ?</h1>
                </div>
                <div className="col-md-12">
                    <div className="row justify-content-center">
                    <div className="col-md-11 my-3 labourhead2body-img labourhead2body-img5 d-flex justify-content-center">
                        <img src={labour5} alt="" />
                    </div>
                    <div className="col-md-11">
                    <p>We are here to help and assist the employers to adhere to all the legislations in terms of Labour law obligations by obtaining required registrations, maintaining required registers, doing periodical returns, etc. We have a strong team of labour law compliance specialists. Our highly competent workforce helps to serve our clients and make then worry free in terms of labour compliance.</p>
              <p>We are specialists in handling several registrations under labour acts in Karnataka, Andhra Pradesh, Maharashtra, Tamil Nadu, Kerala, Orissa, West Bengal, Rajasthan, Punjab, Gujarat , Haryana, Delhi, UP, MP and many other states in India</p>
              <p>We are also specialist in maintaining all necessary registers, records and make periodical returns under several labour acts in India.</p>
              <p>We are specialists in conducting and reporting Labour Law Compliance Due Diligence Audit, Health Check audits and Contractor audits. The team is highly experienced by handling the high end clients.</p>
              <p>Consulting and Advisor is part and parcel of our day to day job. We have a team with high experience in providing advisory and solution to our client’s highly complex problems.</p>
                    </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="labourhead4 col-md-12">
            <div className="row justify-content-center">
                <div className="col-md-12">
                <h1 className='text-center my-3 labourlawhead text-uppercase'>Labour Law Compliances We Provide</h1>
                </div>
                <div className="col-md-11">
                    <div className="row">
                    <div className="col-md-6 mt-5 labour-last-img justify-content-center labour-display-1">
                    <img src={labour6} alt="" />
                    </div>
                    <div className="col-md-6">
                        <ul style={{ listStyleType: "square" }}>
                            <li>Regular Health Check Audit</li>
                            <li>Vendor Audit</li>
                            <li>Due Diligence Audit</li>
                            <li>Registrations under various labour Acts</li>
                            <li>Records and Registers Maintenance</li>
                            <li>PF, ESIC, PT & LWF management and Remittance</li>
                            <li>Consultation and Advisory on the Labour Law</li>
                            <li>Liaison with the statutory authority</li>
                            <li>Processing & Submission of various Returns under Labour Law</li>
                            <li>End-to end POSH Compliance</li>
                            <li>Handling employee cases under Industrial Dispute act</li>
                        </ul>
                        {/* <p>- Regular Health Check Audit.</p>
                        <p>- Vendor Audit</p>
                        <p>- Due Diligence Audit</p>
                        <p>- Registrations under various labour Acts</p>
                        <p>- Records and Registers Maintenance </p>
                        <p>- PF, ESIC, PT & LWF management and Remittance</p>
                        <p>- Consultation and Advisory on the Labour Law</p>
                        <p>- Liaison with the statutory authority</p>
                        <p>- Processing & Submission of various Returns under Labour Law</p>
                        <p>- End-to end POSH Compliance</p>
                        <p>- Handling employee cases under Industrial Dispute act</p> */}
                    </div>
                    <div className="col-md-6 labour-last-img  justify-content-center labour-display-2">
                    <img src={labour6} alt="" />
                    </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
    </div>
  )
}

export default LabourLaw
