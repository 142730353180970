import React from 'react';
import quatation from "../asset/quotations.png"
import "./Testimonial1.css"

const TestiMonialsDetails = ({testiMonialDetail}) => {
    const {name, address, colon , description, img} = testiMonialDetail;
    // console.log("testiMonialDetail"+testiMonialDetail)
    return (
        <div class="item" >
            <div class="shadow-effect">


                <p>{colon}{description}{colon}</p>
                <h5>{name}</h5>
                <small>{address}</small>
            </div>
            <div class="testimonial-name">
            {/* <img class="img-circle" src={img} /> */}
            </div>
        </div>
    );
};

export default TestiMonialsDetails;
