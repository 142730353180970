import React from 'react'
import "./Payroll.css"
import psyrolimage from "../../asset/services/payroll/payroll-new-banner.png"
import employee from "../../asset/services/payroll/empoyee.jpg"
import payrollbenefit from "../../asset/services/payroll/benefit Payroll-Outsourcing-Services.png"
import payrolladdbene from "../../asset/services/payroll/nobackground.jpg"
function Payroll() {
  return (
    <div className="container-fuide">
        <div className="container-fluid payrollheadwer-1 p-0" style={{ backgroundColor:`url(${psyrolimage})`}}>
            <div className=" payrollheadwer-11 pt-5 mt-5">
                <div className="payrollheadcont p-5 mt-5 ">
                <h1> PAYROLL OUTSOURCING SERVICES</h1>
                <h5>"Our payroll services ensure accurate and timely employee payment."</h5>
                {/* <p>"trust out experienced team to handle all of  your payment needs"</p> */}
                <p>"Trust that our experienced team will handle all your Payroll needs."</p>
                </div>
            </div>
        </div>
        <div className="col-md-12 px-3 mt-3 payrollhead2">
            <div className="col-md-12 py-4">
                <h1 className='text-center text-uppercase'>Payroll Outsourcing</h1>
            </div>
            <div className="row d-flex justify-content-center">
                <div className="col-md-11 ddd-flex  justify-content-between">
                <div className="col-md-6 " id='payrolldivorder1'>
                    <p className=' pt-5 '>
                    Employees are the main pillars of any organization. Procesing and paying salary on time, with right calculation is very important. In the meantime, it is more important to have right statutory deductions and compliances to be done with in timeline. To handle and manage complex Income Tax, PF, ESI and other related compliances, the company required great skilled resources. Outsourcing the payroll process is the best option to overcome many problems.
                    </p>
                </div>
                <div className="col-md-4  d-flex justify-content-center" id='payrolldivorder2'>
                    <img className='empy1stimg' src={employee} alt="" />
                </div>
                </div>
            </div>
        </div>
        <div className="col-md-12 px-3 mt-3 payrollhead2">
            <div className="col-md-12 text-center py-4">
                <h1 className='pb-4 text-uppercase'>Benefits Of Payroll Outsourcing </h1>
            </div>
                <div className="row  d-flex justify-content-center">
                    <div className="col-md-11 ddd-flex justify-content-between">
                    <div className="col-md-5  d-flex justify-content-center" >
                        <img src={payrollbenefit} id='payrollbenefit' alt=" no image" />
                    </div>
                    <div className="col-md-6 ">
                        <p className='pt-3'>
                        When a company gets a large number of employees then it becomes a lot more complicated to maintain the entire payroll process.
                            Besides, each employee come with various requirements in terms of their salary calculation. Also, it is important to make employee friendly salary breakup and in the same time adhere to all law requirements.
                            However, if payroll outsourcing service providers are engaged, then the entire burden of running payroll along with meeting statutory requirements can be handed over to the Accunity HR. We shall perform all the necessary tasks such as designing CTC structure, preparing the tax returns, preparing the tax documents, collecting the payroll data, and calculating the salary of the employees.

                        </p>
                    </div>
                    </div>

                </div>
        </div>

    <div className="clas-md-12 px-3 my-3 payrollhead2">
        <div className="col-md-12 text-center py-4">
        <h1 className='text-uppercase'> many more benefits that come with payroll outsourcing</h1>
        </div>
        <div className="row justify-content-center">
                        <div className="col-md-12 text-center my-4">
                        <img className='payrolladdbene  m' src={payrolladdbene} id='payrolladdbene' alt=" no image"  />
                    </div>
                    <div className="col-md-11  ">
                        {/* <h5>Significance impact of not remitting the PT to government: </h5> */}
                        <p>
                        1) Cost reduction
            If you have in house a payroll set up that consists of multiple employees with various level of salaries. The payroll outsourcing services can save you a significant amount of money. Also, you do not have to maintain employees with payroll administration duties, as outsourcing your payroll processing costs eliminates this need. Accunity HR offers best payroll service along with HRMS, that provides employee self-service portal to every employee. The employee can access their Payslip, CTC structure, Income Tax declarations and proof submissions, etc. You will get benefit of onboarding module, managing leave and attendance, asset management, etc.<br />
            2) Focus on the growth of your business
            Outsourcing your payroll allows your company to concentrate on running your business and not on administrative tasks. You no longer need to hire a full-time payroll person who is based in your office.<br />
            3) Compliance with laws and regulations
            Payroll compliance is another reason for outsourcing your payroll to Accunity HR. It is essential for every organization to be in full compliance with all the government regulations that apply to them. These laws impact everything from employee salary, benefits, provident fund, employee state insurance, professional tax, labour welfare fund, Income Tax. Outsourcing your payroll compliance requirements keeps your business compliant and prevents you from being fined. <br />
            4) Increase profits
            In addition to saving money, payroll outsourcing can increase your profits. When you have one provider handling all your payroll, then you can concentrate on running the business. Your staff will use the time to promote more the company and closing more deals, so to increase profits in the short and long term.

                        </p>

                    </div>
        </div>

    </div>

    {/* <div className="col-md-12">
            <h1>Statutory compliance</h1>
            <div className="col-md-12">
                    Employee Provident Fund Organization
                    Every employer who has 20 or more employees (including third party contractor) should register themselves under EPFO.  Employee has to contribute 12% of his/her Basic+DA towards Provident Fund (PF) and the same amount of share has to be made by employer. Apart from this, the employer has to pay 1 % of administration charges to the RPFO.

                    Significance impact of noncontributing the PF: As per section (1A) of EPF & MP Act, 1952, an employer who contravenes, or makes default in complying with, the provisions of section 6 or clause a of sub-section 3 of section 17 in so far as it relates to the payment of inspection charges, or paragraph 38 of the Scheme in so far as it relates to the payment of administrative charges, shall be punishable with imprisonment for a term which may extend to three years but –

                    (a) which shall not be less than one year and a fine of ten thousand rupees in case of default in payment of the employees’ contribution which has been deducted by the employer from the employees’ wages;

                    (b) which shall not be less than six months and a fine of five thousand rupees, in any other case: Provided that the Court may, for any adequate and special reasons to be recorded in the judgment, impose a sentence of imprisonment for a lesser term.

                    Apart from that, interest at the rate 12% (Rule 7Q) per annum and damages at the rate 17% (Rule 14B) per annum till September 2008 and 5% (Rule 14B) per annum after October 2008.

                    Accunity HR helps you to adhere to all statutory requirements under EPFMP act. Also, we will handle any inspections, notices or cases that may ascend.

                    Handling employee query is the most critical and difficult task. Accunity HR has trained and highly skilled workforce who handle your employee queries and help them to resolve issues with respect to provident fund.



                    Employee State Insurance Corporation:
                    Every employer who has 10 or more employees (including third party contractor) should register themselves under ESIC.

                    For all employees earning ₹15,000 (Gross salary) or less per month, the employer contributes 3.25% of the gross salary and the employee contributes 0.75% of the gross salary. Total fund 4% is managed by the ESI Corporation (ESIC) according to rules and regulations stipulated there in the ESI Act 1948, which oversees the provision of medical and cash benefits to the employees and their family. ESI scheme is a type of social security scheme for employees in the organized sector.

                    Significance impact of non-compliance under ESIC Act: As per section 85, 85(a) to 86(g) 85A and 85B, a non complied employer to be punishable with imprisonment for a term which may extend to 24 months [two years] and with fine of five thousand rupees:

                    Along with penalties and damages.
                    Penalties as under:
                    i)   If delay is less than 2 months 	@ 5% per annum
                    ii)   If delay is for 2 months & above
                            but less than 4 months	@ 10% per annum
                    iii)   If delay is for  4 months & above
                        but less than 6 months	@ 15% per annum
                    iv)  If delay is more than 6 months  	@ 25% per annum


                    Accunity HR provides you end to end service in maintaining ESIC compliance.  Starting from Obtaining ESI registration, adding new joiners & generating IPs, exiting non eligible & separated employees, generating monthly contribution challan, monthly returns, half yearly returns, handling inspections and notices, etc.


                    Professional tax:

                    Significance impact of not remitting the PT to government: If an enrolled person or a registered employer fails, without reasonable cause, to make payment of any amount of tax within the required time or date as specified in the notice of demand the assessing authority may, after giving him a reasonable opportunity of making representation, impose upon him a penalty not exceeding fifty per cent of the amount of tax due. This penalty shall be in addition to the interest payable under sub-section (2) or (3) of section 11.

                    Apart from that, the interest at the rate of 12% per annum
                    Damages at the rate of 5% per annum for less than 2 months
                    10% for 2 to 4 months
                    15% for 4 to 6 months
                    25% for 6 months and above.

                    Accunity HR helps you in complaining with professional tax requirements including registration, enrollment, remittance returns, notices etc.

                    labour welfare fund contribution (LWF):

                    Every state has been defined labour welfare fund, it is varied from state to state. For example,
                    >>Haryana state monthly deduction is applicable, employee contribution Rs.10 and employer contribution Rs.20 per employee.
                    >> Maharashtra state half yearly deduction is applicable, employee contribution Rs.12 and employer contribution Rs.36. June and December month.
                    >>Karnataka state yearly deduction is applicable, employee contribution Rs.20 and employer contribution Rs.40

                    Significance impact of non-deduction of LWF  : as per section 17B of The Karnataka Labour Welfare Fund Act, Any person who willfully contravenes any provision of the Act shall on conviction be punished for the first offence with fine which may extend to five hundred rupees or with imprisonment for a term which may extend to three months and for the second and subsequent offences, with fine which may extend to one thousand rupees or with imprisonment for a term which may extend to one year or with both

                    Accunity HR manages registration and contribution of LWF as required by each state.

                    Liaison with government department
                    Liaison with government department to get registrations, submission of returns, if any notice received, to close notices, will also be done by Accunity HR.

            </div>
        </div> */}
    </div>

  )
}

export default Payroll
