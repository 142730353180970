import React ,{useState,useEffect}from 'react'
import "./Buttoner.css"
import Modal from '../Registration/RegisterModel'
import Registration from '../Registration/Registration'
import ChatBot from '../Chatbot/Chatbot'
import {IoCallSharp} from 'react-icons/io5'
import {FaMessage} from 'react-icons/fa6'

function Buttoner() {
  const [isShown, setIsShown] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [showChatbot, setShowChatbot] = useState(false);

    // demo button
      const [demo,setDemo]=useState(false)
  // window.addEventListener('resize',show)
  const regdemo=()=>{
    // console.log(window.scrollY)
    if(window.scrollY >100){
      setDemo(true)
    }else{
      setDemo(false)
    }


  }
  window.addEventListener('scroll', regdemo);
  // hover contact button
  const [isHovered, setIsHovered] = useState(false);
  const [msgHovered, setMsgHovered] = useState(false);

  return (
    <div>
      <div className="App">

            <Modal  show={modalVisible} onClose={() => setModalVisible(false)}>
                <div className="demomodal">
                <h1>Register For Demo</h1>
                </div>
                {/* <Registration /> */}


            </Modal>
        </div>
      <div className={demo ?"stckydiv ":"stckydiv divdis"} >
        <a onClick={() => setModalVisible(true)} >Register for demo</a>
      </div>
      {/* <div className='chatbox'>
        <ChatBot />
      </div> */}
      <div className='callbtn'>
      <div className="phone-link pt-1 pb-2 " onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}>
        <span className="call-icon pb-2 px-3"><IoCallSharp/></span>
        {isHovered && (
        <a href='tel:9886434344' className="phone-number">
        <span className="number px-2">+91 9886-434-344</span>
      </a>
      )}
    </div>
      </div>
      <div className='msgbtn'>
      <div className="msg-link pt-1 pb-2 " onMouseEnter={() => setMsgHovered(true)}
      onMouseLeave={() => setMsgHovered(false)}>
        <span className="msg-icon pb-2 px-3"><FaMessage/></span>
        {msgHovered && (
        <a href="mailto: leads@accunityhr.com"  className="msg-id">
        <span className="msg px-2">leads@accunityhr.com</span>
      </a>
      )}
    </div>
      </div>

    </div>
  )
}

export default Buttoner
