// SweetAlert.js
import React, { useEffect } from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);

const SweetAlert = ({ show, type, title, text, onClose }) => {
  useEffect(() => {
    if (show) {
      MySwal.fire({
        icon: type, // 'success', 'error', 'warning', etc.
        title,
        text,
      }).then(() => {
        if (onClose) {
          onClose();
        }
      });
    }
  }, [show, type, title, text, onClose]);

  return null;
};

export default SweetAlert;
