import React, { useState, useEffect } from "react";
import "./App.css"
import 'animate.css';
// import { Route,Router,Routes } from "react-router-dom";
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom'
import Menu from "./Component/menu&footer/Menu";
import Home from "./Component/home/home";
import Contact from "./Component/Contact/Contact";
import Footer from "./Component/menu&footer/footer";
import About from "./Component/About/About";
import Buttoner from "./Component/buttoner/Buttoner";
import Registration from "./Component/Registration/Registration";
import LabourLaw from "./Component/Services/services/LabourLaw";
import MinimumWages from "./Component/Library/MinimumWages";
import MinwageSingledata from "./Component/Library/MinwageSingledata";
import Login from "./Component/Registration/Logi";
import Payroll from "./Component/Services/payroll/Payroll";
import Statutory from "./Component/Services/statutory/Statutory";
import Loader from "./loader/Loader";
import HRconsultent from "./Component/Services/HRconsultent/HRconsultent";
import Staffing from "./Component/Services/staffing/Staffing";
import Training from "./Component/Services/Training/Training";
import Pnf from "./Component/Page-not-found/Pnf";
function App() {
    const handleLogin = (credentials) => {
        console.log('Logging in with credentials:', credentials);
    };
    const [loading, setLoading] = useState(true);
    const [showAnimation, setShowAnimation] = useState(false);

    // useEffect(() => {
    //     window.scrollTo(0, 0);
    //     if (window.location.pathname === '/home') {
    //         setShowAnimation(true);

    //         setTimeout(() => {
    //           setShowAnimation(false);
    //           setLoading(true);
    //         }, 4000);
    //       } else {
    //         setLoading(true);
    //       }
    // }, []);
  return (


            <div className="app-container ">
                {showAnimation && <div className="animated-div"><Loader /></div>}
                <div className={`content ${loading ? 'fade-in' : ''}`}>
                    <Router>
                    <Menu/>
                    <Buttoner/>
                    <Routes >
                        <Route path="/register" element={<Registration/>}/>
                        <Route path="/home" element={<Home/>}/>
                        <Route path="/" element={<Navigate to="/home" />} />
                        <Route path="/about" element={<About/>}/>
                        <Route path="/aboutt" element={<Loader/>}/>
                        <Route path="/contact" element={<Contact/>}/>
                        <Route path="/login" element={<Login onLogin={handleLogin}/>}/>

                        <Route path="/service/labour" element={<LabourLaw/>}/>
                        <Route path="/service/payroll" element={<Payroll/>}/>
                        <Route path="/service/statutory" element={<Statutory/>}/>
                        <Route path="/service/consulting" element={<HRconsultent/>}/>
                        <Route path="/service/staffing" element={<Staffing/>}/>
                        <Route path="/service/training" element={<Training/>}/>



                        <Route path="library/minimum-wages/" element={<MinimumWages/>}/>
                        <Route path="library/:minState/:minact" element={<MinwageSingledata/>}/>
                        <Route path="/*" element={<Pnf/>}/>
                    </Routes>
                    <Footer/>
                </Router>
                </div>
            </div>
    );
}

export default App;
