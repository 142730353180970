import React from 'react'
import "./About.css"
import TestimonialCarousel from "../Testimonial/Testimonial1"


function About() {
  return (
    <div className="container-about">
    <div id='about' className='container-fluid'>
        <div className='about_data'>
            <h1>ABOUT US</h1>
            <p>
                Accunity HR Services Private Limited was established in 2014, to serve companies of various sectors to adhere to all the legislations in terms of Payroll, Labour Law and Statutory Compliances.
            </p>
            <p>
                Accunity HR is a rapid growing SPO (Service Process Outsourcing) company dedicated to provide Payroll, Labour Law Compliances, Statutory Compliance, Staffing Solutions & HR Consultation services. We are committed to providing business solutions to our clients and address their concerns associated with these services.
            </p>
            <p>
            Accunity HR provides a full assortment of payroll solution, which helps organizations streamline their payroll processes and enable in better payroll administration. Our payroll solution can be personalized in accordance to the customer requirement. We are experts in handling Employee Benefit Administration, Tax Planning, Flexible Benefits/Compensation, Business reimbursement administration, Income tax filing, etc.
            </p>
            <p>
            Accunity HR assist the companies to adhere to all the legislations in terms of Labour Acts. We have a strong team of labour law compliance specialists. We shall serve our clients and make them worry free in terms of labour legislations. We have reach in across India viz. Andhra Pradesh, Bihar, Chhattisgarh, Delhi, Haryana, Goa, Gujarat, Jharkhand, Karnataka, Kerala, Madhya Pradesh, Odisha, Punjab, Rajasthan, Tamil Nadu, Telangana, Uttarkhand, Uttar Pradesh, West Bengal.
            </p>

        </div>
        <div className="about_cards">
            <div className="about_card">
                <h1>Vision</h1>
                <p>
                Serve our clients by delivering excellent service timely and in an accurate manner. Ensure that ethics & values are the fundamental that lies at the core of our operations.
                </p>
            </div>
            <div className="about_card">
                <h1>
                Mission
                </h1>
                <p>A one stop solution provider for an entire gamut of human resource related services, that the organizations can leverage, by having their HR processes outsourced to a team of highly skilled professionals who have domain expertise in this area and who constantly strive to deliver their best.</p>
            </div>
            <div className="about_card">
                <h1>Values</h1>
                <p>Being honest, Being accountable, Doing what is right, Being professional, Maintaining confidentiality, Keeping commitments, Culture of Candor & Act with integrity</p>
            </div>
        </div>
    </div>
    <div>
    </div>
    </div>
  )
}

export default About