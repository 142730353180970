import React from 'react'
import './Pnf.css'
import pnf from "../asset/pnf.gif"

function Pnf() {
  return (
    <div className='pnf-img'>
        <img src={pnf} alt="" />
    </div>
  )
}

export default Pnf
